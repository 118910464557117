import React from "react"

import Layout from '../components/layout'
import Head from '../components/head'

const about = () => {
    return (
        <Layout>
            <Head  title="About" pageDescription="Learn more about the nfelo model and app"/>
            <h1>About nfelo</h1>
            <p>nfelo is a predictive model for NFL games. Mechanically, it combines 538's Elo model with research driven modifications to create a model tailored specifically to the NFL--hence the name nfelo (NFL + Elo).</p>
            <p>This site is a front-end app for exploring the model and its output. It features game predictions, power rankings, and QB values.</p>
            <p>In addition to the model, this site also features other useful information like historical box scores with advanced analaytics and expected value calculations to use when thinking about wagering.</p>
        </Layout>
    )
}

export default about

